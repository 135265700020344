import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navbar, Dropdown, Image, Button, ProgressBar } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { openModal } from 'modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import { CreateProject } from 'modules/project/ProjectModService';
import { clearProjectMod, createProject } from 'modules/project/ProjectModReducer';
import { RootState } from 'modules';
import { CustomDropdownToggle, SvgIcon, SvgIconSource, Typography } from 'components';
import style from './Header.module.scss';
import logo from 'public/logo.svg';
import logo_gray from 'public/logo_gray.svg';
import { getUserProjectAndCompany } from 'modules/dashboard/IndexThunk';
import { fileUrl } from 'util/fileHelper';
import { getUserProfile, getUserResourceUsage, logoutUser } from 'modules/user/UserReducer';
import { AiResourceQuota } from '../../modules/user/User';

type DropdownLogoProps = { addCompany: () => void };
type HeaderProps = { logoOnly: boolean };

const DropdownLogo = ({ }: DropdownLogoProps) => {
  const { companies } = useAppSelector((state: RootState) => state.index);
  const { project } = useAppSelector((state: RootState) => state.project);
  const state = useSelector((rs: RootState) => rs.index);
  const { projects } = state;


  const selectedProject = useMemo(() => {
    return project.data;
  }, [companies, projects]);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomDropdownToggle}>
        <span className={style.logo_wrapper}>
          <Image src={logo} height={30} />
          <span className="flex-1 px-3">{/* <Image src={stripes} height={14} /> */}</span>
          <span>
            <SvgIcon source={SvgIconSource.Caret} color="bluegray-300" />
          </span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.groupMenu}>
        {selectedProject && (
          <Dropdown.Item href={`/project/${selectedProject.projectNo}`} className={style.groupItem}>
            <Image
              src={selectedProject.thumbnail ? fileUrl(selectedProject.thumbnail) : logo_gray}
              className={style.groupImage}
              rounded
            />
            <Typography>{selectedProject?.name}</Typography>
          </Dropdown.Item>
        )}
        {/* <Dropdown.Item className={classNames(style.groupItem, 'bg-blue-10')} onClick={() => navigate('/plan')}>
          <Image src={rocket} />
          <div className="text-wrap lh-4" style={{ width: '138px' }}>
            <Typography tag="span" variant="f12-normal--48">
              유료 플랜으로 더 많은 기능을 경험해보세요!
            </Typography>{' '}
            <a href="/" className="text-red-50">
              <Typography tag="span" variant="f12-normal--48" className="fw-bold">
                옵션 보러가기
              </Typography>
            </a>
          </div>
        </Dropdown.Item> */}
        {projects?.map((proj, index) => {
          if (selectedProject && selectedProject.projectNo === proj.projectNo) return null;
          return (
            <Dropdown.Item href={`/project/${proj.projectNo}`} key={index} className={style.groupItem}>
              <Image
                src={proj.thumbnail ? fileUrl(proj.thumbnail) : logo_gray}
                className={style.groupImage}
                rounded
              />
              <Typography>{proj.name}</Typography>
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item href="/project/register" className={style.groupItem} eventKey="1">
          <div
            className={classNames(
              style.groupImage,
              'd-flex align-items-center justify-content-center bg-blue-gray-100'
            )}>
            <SvgIcon source={SvgIconSource.Plus} color="bluegray-300" />
          </div>
          <Typography>다른 프로젝트</Typography>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ProfileDropdown = () => {
  const dispatch = useAppDispatch();
  const { profile } = useSelector((state: RootState) => state.user);

  const { name, email, thumbnail } = profile?.data || {};

  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      window.location.href = '/login';
    });
  };

  return (
    <Dropdown drop="down-centered">
      <Dropdown.Toggle as={CustomDropdownToggle} id="dropdown-profile">
        <Avatar src={thumbnail ? fileUrl(thumbnail, true) : ''} className={style.avatar} alt={name || ''} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.profileMenu}>
        <Dropdown.ItemText className={style.profileItem}>
          <Typography variant="f12-normal--48" className="fw-bold">
            내 프로필
          </Typography>
        </Dropdown.ItemText>
        <Dropdown.ItemText className={style.profileItem}>
          <Avatar src={thumbnail || ''} className={style.avatar} alt={name || ''} />
          <div>
            <Typography variant="f12-normal--48" className="fw-medium">
              {name || ''}
            </Typography>
            <Typography variant="f10-normal--40" className="fw-medium text-blue-gray-300">
              {email || ''}
            </Typography>
          </div>
        </Dropdown.ItemText>
        <Dropdown.Divider></Dropdown.Divider>
        <Dropdown.Item href="/profile" className={style.profileItem}>
          <SvgIcon source={SvgIconSource.User} />
          <Typography variant="f12-normal--48" className="fw-medium">
            마이페이지
          </Typography>
        </Dropdown.Item>
        <Dropdown.Item className={style.profileItem} onClick={handleLogout}>
          <SvgIcon source={SvgIconSource.SignOut} color="red-50" />
          <Typography variant="f12-normal--48" className="fw-medium text-red-50">
            로그아웃
          </Typography>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};


const UserResourceUsage = () => {

  const { resourceUsage } = useSelector((state: RootState) => state.user);
  const aiResourceUsage = AiResourceQuota.fromQuotaUsage(resourceUsage.data);
  const now = aiResourceUsage.percent;
  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-US').format(num);
  };
  const getVariant = (currentPercent: number) => {
    if (currentPercent < 70) return '';
    if (currentPercent < 90) return 'warning';
    return 'danger';
  };


  return (
    <div className={style.usage_container}>
      <Typography variant="f12-normal--48" className="fw-medium mb-2">
        토큰 사용량: {formatNumber(aiResourceUsage.use)} / {formatNumber(aiResourceUsage.quota)}
      </Typography>
      <div className={style.progress_container}>
        <ProgressBar className="position-relative">
          <div className="position-absolute d-flex justify-content-center w-100 z-1">
            <Typography variant="f12-normal--48--shadow" className="fw-medium mb-2">
              {`${now}%`}
            </Typography>
          </div>
          <ProgressBar
            striped
            now={now}
            key={1}
            variant={getVariant(now)}
            className="position-relative"
          />
        </ProgressBar>
      </div>
    </div>
  );
};

export default function Header({ logoOnly }: HeaderProps) {
  const dispatch = useAppDispatch();
  const projectModState = useSelector((state: RootState) => state.projectMod);

  const projectComplete = projectModState.complete;

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getUserProjectAndCompany());
    dispatch(getUserResourceUsage());
  }, []);

  useEffect(() => {
    if (projectComplete.data) {
      dispatch(clearProjectMod());
      // TODO useNavigate로 이관 필요
      location.href = '/project/' + projectComplete.data;
    }
  }, [projectComplete]);

  const openProjectModal = () => {
    dispatch(
      openModal({
        type: ModalTypes.CreateProject,
        props: {
          onConfirm: (param: CreateProject) => {
            dispatch(createProject(param));
          },
        },
        overlayOptions: {
          modalPos: { y: 3 },
          translate: { y: 0 },
        },
      })
    );
  };

  return (
    <>
      <header id={style.header}>
        <Navbar className={style.navbar}>
          <DropdownLogo addCompany={openProjectModal} />
          {!logoOnly && (
            <div className="d-flex w-100 align-items-center justify-content-end gap-x-5 px-5">
              <UserResourceUsage/>
              <div className="d-flex w-100 align-items-center justify-content-end gap-x-5 px-5">
                <ProfileDropdown/>
                <Button
                  href="/"
                  variant="outline-primary"
                  className="w-32 d-flex align-items-center justify-content-center bg-white text-primary gap-1">
                  <SvgIcon source={SvgIconSource.HomeFill} color="primary"/>
                  <Typography className="fw-bold">홈으로 이동</Typography>
                </Button>
              </div>
            </div>
          )}
        </Navbar>
      </header>
    </>
  );
}
