import React, { useState } from 'react';
import styles from './DropDown.module.scss';
import { SearchListValue } from 'components/modal';
import { DropDown } from './DropDownContainer';
import { Avatar } from '@mui/material';

export type DropDownListProps = {
  title?: string;
  items: SearchListValue[];
  onClickItem: (listItem: SearchListValue) => void;
  style?: React.CSSProperties;
  [key: string]: any;
};

export const DropdownList = ({ title, items, style, onClickItem }:DropDownListProps) => {
  
  return (
    <DropDown.Container style={{ padding:`var(--f6) 0`, ...style }}>
      {title && <DropDown.Title text={title} style={{ padding:`0 var(--f10)`, color:`var(--gray-50)` }} />}
      <DropDown.Menu style={{ maxHeight: '230px' }}>
        {items.map((item, index) => (
          <DropDown.Item key={index} onClick={() => {if (onClickItem)onClickItem(item);}}>
            {item.imgPath && <Avatar src={item.imgPath} sx={{ width: 24, height: 24 }} />}
            <span className={styles.search_list_item_text}>{item.text}</span>
          </DropDown.Item>
        ))}
      </DropDown.Menu>
    </DropDown.Container>
  );
};


export const InputDropdownList = ({ initVal, items, style, onClickItem }:DropDownListProps) => {
  const [inputValue, setInputValue] = useState<SearchListValue>({ text: initVal });
  const [filteredItems, setFilteredItems] = useState<SearchListValue[]>(items);

  React.useEffect(() => {
    if (items) {
      const filtered = items.filter((item) => item.text != initVal);
      setFilteredItems(filtered);
    }
  }, [items, initVal]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue({ text: e.target.value });
    const filtered = items.filter((item) => item.text.includes(e.target.value));
    setFilteredItems(filtered);
  };
  
  return (
    <DropDown.Container style={{ padding:`var(--f6) 0`, ...style }}>
      <form
        onSubmit={(e)=>{
          e.preventDefault();
          let item = items.find(val => val.text.trim() == inputValue.text.trim()) || { text: inputValue.text.trim() };
          onClickItem(item);
        }}
      >
        <DropDown.SearchBar 
          placeholder="비어있음"
          value={inputValue.text}
          onChange={onChangeHandler}
        />
      </form>
      <DropDown.Menu style={{ maxHeight: '230px' }}>
        {filteredItems.map((item, index) => (
          <DropDown.Item key={index} onClick={() => {onClickItem(item);}}>
            <span className={styles.search_list_item_text}>{item.text}</span>
          </DropDown.Item>
        ))}
      </DropDown.Menu>
    </DropDown.Container>
  );
};